<template>
    <div id="#app">
        <!-- 用户已设置订阅信息 -->
        <div>
            <p class="head">订阅方式<font color="#a0a0a0"> (设置别人订阅你时 所需的知识币) </font></p>
            <div class="subset-body">
                <p class="title">基本费用+折扣</p>
                <div class="container">
                    <el-table highlight-current-row
                        :data="tableData"
                        @row-click="rowClick"
                        style="width: 100%">
                            <!-- <el-table-column label width="35">
                                <template slot-scope="scope">
                                    <el-radio :label="scope.row.index" v-model="radioID1">{{&nbsp;}}</el-radio>
                                </template>
                            </el-table-column> -->
                            <el-table-column
                                align="center"
                                prop="date"
                                label="时间"
                                width="180">
                            </el-table-column>
                            <el-table-column
                                align="center"
                                prop="cost"
                                label="知识币"
                                width="180">
                            </el-table-column>
                            <el-table-column
                                align="center"
                                prop="discount"
                                label="折扣">
                            </el-table-column>
                    </el-table>
                </div>
                <!-- 一次付清 -->
                <p class="title">一次性付费</p>
                <div class="container">
                    <el-table
                        :data="tableData2"
                        style="width: 100%"
                        @row-click="rowClick2">
                            <!-- <el-table-column label width="35">
                                <template slot-scope="scope">
                                    <el-radio :label="scope.row.index" v-model="radioID2">{{&nbsp;}}</el-radio>
                                </template>
                            </el-table-column> -->
                            <el-table-column
                                align="center"
                                prop="date"
                                label="时间"
                                width="">
                            </el-table-column>
                            <el-table-column
                                align="center"
                                prop="cost"
                                label="知识币"
                                width="">
                            </el-table-column>
                    </el-table>
                </div>
                <div class="btn">
                    <el-button size="mini" @click="centerDialogVisible=true" v-show="showWho">修改</el-button>
                    <el-button size="mini" @click="centerDialogVisible=true" v-show="!showWho">去设置</el-button>
                    <!-- <el-button type="primary" size="mini">保存</el-button> -->
                </div>
            </div>
        </div>
        <!-- 新用户设置订阅信息 -->
        <!-- <div v-if="!initailize">
            新用户需要设置
        </div> -->
        <div class="setInfo">
            <el-dialog
            title="订阅设置"
            :visible.sync="centerDialogVisible"
            width="46%"
            :center="true"
            :close-on-click-modal="false"
            >
               
                <el-form :inline="true" :model="formInlineData" ref="formInlineData" class="demo-form-inline"
                >
                    <!-- 基本费用加折扣 -->
                    <div id="base-and-disct">
                        <h4>基本费用+折扣</h4>
                        <p class="bold">包月： </p>
                        <el-form-item label="知识币:" >
                                <el-input maxlength="3" @input="e=>(formInlineData.money_60=proving(e))"  :value="formInlineData.money_60" placeholder="知识币"></el-input>
                        </el-form-item>
                        <!-- <el-form-item label="折 扣:" >
                                <el-input maxlength="3" @input="e=>(formInlineData.discount_60=provingDiscount(e))"  :value="formInlineData.discount_60" placeholder="折扣"></el-input>
                        </el-form-item> -->
                        <el-form-item label="折 扣:" >
                                <el-input maxlength="3" @blur="formInlineData.discount_60=provingDiscount(formInlineData.discount_60)" v-model="formInlineData.discount_60" placeholder="折扣"></el-input>
                        </el-form-item>
                        <p class="bold">包季： </p>
                        <el-form-item label="知识币:" >
                                <el-input maxlength="3" @input="e=>(formInlineData.money_61=proving(e))" :value="formInlineData.money_61" placeholder="知识币"></el-input>
                        </el-form-item>
                        <el-form-item label="折 扣:" >
                                <el-input maxlength="3" @blur="formInlineData.discount_61=provingDiscount(formInlineData.discount_61)"  v-model="formInlineData.discount_61" placeholder="折扣"></el-input>
                        </el-form-item>
                        <p class="bold">包年： </p>
                        <el-form-item label="知识币:" >
                                <el-input maxlength="3" @input="e=>(formInlineData.money_62=proving(e))" :value="formInlineData.money_62" placeholder="知识币"></el-input>
                        </el-form-item>
                        <el-form-item label="折 扣:" >
                                <el-input maxlength="3" @blur="formInlineData.discount_62=provingDiscount(formInlineData.discount_62)"  v-model="formInlineData.discount_62" placeholder="折扣"></el-input>
                        </el-form-item>
                    </div>
                    <!-- 一次性付清 -->
                    <div id="once-pay-all">
                        <h4 id="once-title">一次性付清</h4>
                        <div class="once">
                            <p class="bold">包月： </p>
                            <el-form-item label="知识币:" >
                                    <el-input maxlength="3" @input="e=>(formInlineData.money_73=proving(e))" :value="formInlineData.money_73" placeholder="知识币"></el-input>
                            </el-form-item>
                        </div>
                        
                        <div class="once">
                            <p class="bold">包季： </p>
                            <el-form-item label="知识币:" >
                                    <el-input maxlength="3" @input="e=>(formInlineData.money_74=proving(e))" :value="formInlineData.money_74" placeholder="知识币"></el-input>
                            </el-form-item>
                        </div>
                        
                        <div class="once">
                            <p class="bold">包年： </p>
                            <el-form-item label="知识币:" >
                                    <el-input maxlength="3" @input="e=>(formInlineData.money_75=proving(e))" :value="formInlineData.money_75" placeholder="知识币"></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    
                </el-form>
                <div class="center">
                    <font color="#a0a0a0">温馨提示: 折扣(1-9.9) 知识币(正整数)</font>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="centerDialogVisible = false">取 消</el-button>
                    <!-- 插入按钮 -->
                    <el-button type="primary" @click="insertSubs" :loading="loading_save" v-show="!showWho">保 存</el-button>
                    <!-- 修改按钮 -->
                    <el-button type="primary" @click="updateSubs" :loading="loading_update" v-show="showWho">更 新</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import {QUERYED,UPDATES,INSERT, UPDATE} from '@/services/dao.js';
export default {
    data(){
        
        return{
            initailize: true,
            centerDialogVisible: false, // 设置订阅弹窗知识币
            // 设置或修改的初始数据
            formInlineData:{
                money_60: "",
                discount_60: "",
                money_61: "",
                discount_61: "",
                money_62: "",
                discount_62: "",
                money_73: "",
                money_74: "",
                money_75: "",  
            },
            tableData: [],// 基本折扣
            tableData2:[],// 一次付清
            radioID1: 0,
            radioID2: 3,
            showWho: false, // 修改,设置(默认) 按钮显隐
            maxCoin: 500, // 设置最大知识币
            minCoin: 1, // 设置最小知识币
            loading_save: false, // 订阅设置知识币加载图标
            loading_update: false, // 订阅更新知识币加载图标
        }
    },
    created(){
       this.inint();
    },
    methods:{
        async inint(){
            let res = await QUERYED("post","","SubscribeSet(where:{user_id:{_eq:"+this.$store.state.userinfo.id+"}}) {setType typeOfDate money discount}")
            console.log(res);
            let result = res.data.SubscribeSet;
            if(!result.length == 0){
                this.showWho = true;
            }else{
                this.showWho = false;
            }
            // result = JSON.stringify(result);
            console.log("JSON.stringify(result): ",result);
            // 初始化tableData tableData2数据
            result.sort( (a, b)=> { // sort对原数组进行操作
                // console.log(a[x])
                var x = 'typeOfDate'//要排序字段
                if (a[x] < b[x]) {
                return -1
                }
                if (a[x] > b[x]) {
                return 1
                }
                return 0;
            });
            console.log("sort res: ",result);
            this.tableData = [];
            this.tableData2 = [];
            for(let i=0;i<result.length;i++){
                if(i<3){
                    this.tableData.push({
                        date:i==0?"包月":(i==1?"包季":"包年"),
                        cost: result[i].money,
                        discount: result[i].discount,
                        index: i,
                    })
                }else{
                    this.tableData2.push({
                        date:i==3?"包月":(i==4?"包季":"包年"),
                        cost: result[i].money,
                        index: i,
                    })
                }
                console.log(this.tableData2)
            }
            // 为修改表单赋值
            if(!result.length == 0){
                for(let i=0;i<result.length;i++){
                    // console.log("result: ",result)
                    if(i ==0){
                        this.formInlineData.money_60 = result[0].money;
                        this.formInlineData.discount_60 = result[0].discount;
                    }else if(i==1){
                        this.formInlineData.money_61 = result[1].money;
                        this.formInlineData.discount_61 = result[1].discount;
                    }else if(i==2){
                        this.formInlineData.money_62 = result[2].money;
                        this.formInlineData.discount_62 = result[2].discount;
                    }else if(i==3){
                        this.formInlineData.money_73 = result[3].money;
                    }else if(i==4){
                        this.formInlineData.money_74 = result[4].money;
                    }else{
                        this.formInlineData.money_75 = result[5].money;
                        
                    }
                }
            }
        },
        // 更新订阅设置
        async updateSubs(){
            if(this.checkNull()){
                return ;
            }
            this.loading_update = true;
            // 折扣 包月
            let res1 = await UPDATE("post","",'update_SubscribeSet(where:{user_id:{_eq:'+
            this.$store.state.userinfo.id+
            '},typeOfDate:{_eq:'+0+'}},_set:{money:'+this.formInlineData.money_60+
            ',discount:"'+this.formInlineData.discount_60+'"}) {affected_rows}');
            if(res1.data.update_SubscribeSet.affected_rows !==1){
                this.$message.warning({
                    message: '服务器繁忙,稍后再试~',
                    duration: 1000
                })
                return;
            }
            // console.log("update_res1: ",res1);
            // 折扣 包季
            let res2 = await UPDATE("post","",'update_SubscribeSet(where:{user_id:{_eq:'+
            this.$store.state.userinfo.id+
            '},typeOfDate:{_eq:'+1+'}},_set:{money:'+this.formInlineData.money_61+
            ',discount:"'+this.formInlineData.discount_61+'"}) {affected_rows}');
            if(res2.data.update_SubscribeSet.affected_rows !==1){
                this.$message.warning({
                    message: '服务器繁忙,稍后再试~',
                    duration: 1000
                })
                return;
            }
            // 折扣 包年
            let res3 = await UPDATE("post","",'update_SubscribeSet(where:{user_id:{_eq:'+
            this.$store.state.userinfo.id+
            '},typeOfDate:{_eq:'+2+'}},_set:{money:'+this.formInlineData.money_62+
            ',discount:"'+this.formInlineData.discount_62+'"}) {affected_rows}');
            if(res3.data.update_SubscribeSet.affected_rows !==1){
                this.$message.warning({
                    message: '服务器繁忙,稍后再试~',
                    duration: 1000
                })
                return;
            }
            // 一次性 包月
            let res4 = await UPDATE("post","",'update_SubscribeSet(where:{user_id:{_eq:'+
            this.$store.state.userinfo.id+
            '},typeOfDate:{_eq:'+3+'}},_set:{money:'+this.formInlineData.money_73+
            '}) {affected_rows}');
            if(res4.data.update_SubscribeSet.affected_rows !==1){
                this.$message.warning({
                    message: '服务器繁忙,稍后再试~',
                    duration: 1000
                })
                return;
            }
            // 一次性 包季
            let res5 = await UPDATE("post","",'update_SubscribeSet(where:{user_id:{_eq:'+
            this.$store.state.userinfo.id+
            '},typeOfDate:{_eq:'+4+'}},_set:{money:'+this.formInlineData.money_74+
            '}) {affected_rows}');
            if(res5.data.update_SubscribeSet.affected_rows !==1){
                this.$message.warning({
                    message: '服务器繁忙,稍后再试~',
                    duration: 1000
                })
                return;
            }
            // 一次性 包年
            let res6 = await UPDATE("post","",'update_SubscribeSet(where:{user_id:{_eq:'+
            this.$store.state.userinfo.id+
            '},typeOfDate:{_eq:'+5+'}},_set:{money:'+this.formInlineData.money_75+
            '}) {affected_rows}');
            if(res6.data.update_SubscribeSet.affected_rows !==1){
                this.$message.warning({
                    message: '服务器繁忙,稍后再试~',
                    duration: 1000
                })
                return;
            }
            this.loading_update = false;
            this.centerDialogVisible = false;
            this.$message.success({
                duration: 1000,
                message: "修改成功!"
            })
            console.log("1111111");
            this.inint();
        },
        // 设置订阅设置
        async insertSubs(){
            if(this.checkNull()){
                return ;
            }
            this.loading_save = true;
            // 折扣 包月
            let res1 = await INSERT("post","",'insert_SubscribeSet(objects:{user_id:'+
            this.$store.state.userinfo.id+
            ',setType:'+6+',typeOfDate:'+0+',money:'+this.formInlineData.money_60+
            ',discount:"'+this.formInlineData.discount_60+'"}) {affected_rows}');
            if(res1.data.insert_SubscribeSet.affected_rows !==1){
                this.$message.warning({
                    message: '服务器繁忙,稍后再试~',
                    duration: 1000
                })
                return;
            }
            // 折扣 包季
            let res2 = await INSERT("post","",'insert_SubscribeSet(objects:{user_id:'+
            this.$store.state.userinfo.id+
            ',setType:'+6+',typeOfDate:'+1+',money:'+this.formInlineData.money_61+
            ',discount:"'+this.formInlineData.discount_61+'"}) {affected_rows}');
            if(res2.data.insert_SubscribeSet.affected_rows !==1){
                this.$message.warning({
                    message: '服务器繁忙,稍后再试~',
                    duration: 1000
                })
                return;
            }
            // 折扣 包年
            let res3 = await INSERT("post","",'insert_SubscribeSet(objects:{user_id:'+
            this.$store.state.userinfo.id+
            ',setType:'+6+',typeOfDate:'+2+',money:'+this.formInlineData.money_62+
            ',discount:"'+this.formInlineData.discount_62+'"}) {affected_rows}');
            if(res3.data.insert_SubscribeSet.affected_rows !==1){
                this.$message.warning({
                    message: '服务器繁忙,稍后再试~',
                    duration: 1000
                })
                return;
            }
            // 一次性 包月
            let res4 = await INSERT("post","",'insert_SubscribeSet(objects:{user_id:'+
            this.$store.state.userinfo.id+
            ',setType:'+7+',typeOfDate:'+3+',money:'+this.formInlineData.money_73+
            '}) {affected_rows}');
            if(res4.data.insert_SubscribeSet.affected_rows !==1){
                this.$message.warning({
                    message: '服务器繁忙,稍后再试~',
                    duration: 1000
                })
                return;
            }
            // 一次性 包季
            let res5 = await INSERT("post","",'insert_SubscribeSet(objects:{user_id:'+
            this.$store.state.userinfo.id+
            ',setType:'+7+',typeOfDate:'+4+',money:'+this.formInlineData.money_74+
            '}) {affected_rows}');
            if(res5.data.insert_SubscribeSet.affected_rows !==1){
                this.$message.warning({
                    message: '服务器繁忙,稍后再试~',
                    duration: 1000
                })
                return;
            }
            // 一次性 包年
            let res6 = await INSERT("post","",'insert_SubscribeSet(objects:{user_id:'+
            this.$store.state.userinfo.id+
            ',setType:'+7+',typeOfDate:'+5+',money:'+this.formInlineData.money_75+
            '}) {affected_rows}');
            if(res6.data.insert_SubscribeSet.affected_rows !==1){
                this.$message.warning({
                    message: '服务器繁忙,稍后再试~',
                    duration: 1000
                })
                return;
            }
            this.loading_save = false;
            this.centerDialogVisible = false;
            this.$message.success({
                duration: 1000,
                message: "设置成功!"
            })
            console.log("1111111");
            this.inint();
        },
        checkNull(){
            if(this.formInlineData.money_60 =="" || this.formInlineData.discount_60=="" ||
                this.formInlineData.money_61 == "" || this.formInlineData.discount_61 == "" ||
                this.formInlineData.money_62 == "" || this.formInlineData.discount_62 == "" ||
                this.formInlineData.money_73 == "" || this.formInlineData.money_74 == "" || this.formInlineData.money_75 == ""
            ){
                this.$message.warning({
                    message: "请完善输入数据!",
                    duration: 1200
                })
                return true;
            }else{
                return false;
            }
        },
        // proving() {
        //     this.rechargeNum = this.rechargeNum
        //         .replace(/[^\.\d]/g, "")
        //         .replace(/^0{1,}/g, "");
        //     this.rechargeNum = this.rechargeNum.replace(".", "");
        // },
        proving(val) {
            console.log(val);
            val = val
                .replace(/[^\.\d]/g, "")
                .replace(/^0{1,}/g, "")
                .replace(".", "");
            
            // console.log("money_60: ",this.formInlineData.money_60);
            return val;
        },
        provingDiscount(val){
            console.log(val);
            // let reg = /^([1-9]{1}(\.[0-9])?)|(0\.[1-9])$/;
            let reg = /^([1-9]{1}(\.[0-9])?)$/;
            console.log(reg.test(val))
            if(reg.test(val)){
                return val;
            }else{
                val = "";
                return val;
            }
            
        },
        // type=number 输入限制
        // fixInputLimit(e){
        //     console.log(e);
        //     let key = e.key;
        //     if(key ==="e" || key==="."){
        //         e.returnValue = false;
        //         return false;
        //     }
        //     return true;
        // },
        rowClick(row){
            // console.log(row.index)

            this.radioID1=row.index;
            
            console.log(this.radioID1);
            // console.log(column)
            // console.log(event)
        },
        rowClick2(row){
            console.log(row.index);
            this.radioID2=row.index;
            
        }
    }
}
</script>
<style scoped>
/* public css */
#app{
    font-size: 12px;
}
.container{
    border: 1px solid #ebeef5;
}
.title{
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #030303;
    text-align: center;
}
.bold{
    font-weight: bold;
}
.marg10-l{
    margin-left: 10px;
}
.center{
    text-align: center;
}
/* private css */
.head{
  font-size:14px;
  font-family:Microsoft YaHei;
  font-weight:400;
  color:rgba(3,3,3,1);
  font-weight: 800;
  padding-bottom: 15px;
}
.subset-body{
    border:solid 0.007353rem #e8e8e8;
    /* background-color: cadetblue; */
    padding: 8px;
}
.btn{
    padding: 20px;
    text-align: center;
}
.once{
    display: inline-block;
}
#base-and-disct{
    display: inline-block;
}
#once-pay-all{
    display: inline-block;
    width: 180px;
    margin-left: 40px;
}
#once-title{
    margin-block-end: 4px;
}
/* reset css */
.setInfo >>>.el-form-item__content{
    width: 85px;
}
.setInfo form{
    margin-left: 30px;
}
.setInfo >>>.el-dialog--center .el-dialog__body {
    text-align: initial;
    padding: 25px 25px 5px;
}
</style>